<template lang="html" src="./pageInternalWarehouseParts.template.vue"></template>

<style lang="scss" src="./pageInternalWarehouseParts.scss"></style>

<script>
import PartsTable from '../../components/partsTable/PartsTable';
import UploadOrgParts from '../../components/uploadOrgParts/UploadOrgParts';

import { DropZoneContainer } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageInternalWarehouseParts.i18n');

export default {
  name: 'PageInternalWarehouseParts',
  components: {
    DropZoneContainer,
    PartsTable,
    UploadOrgParts,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      internalParts: [],
      supplierPartQuota: null,
    };
  },
  created() {
    this.getSupplierInternalParts();
    this.getSupplierFeatures();
  },
  mounted() {},
  methods: {
    getSupplierInternalParts() {
      this.internalParts = [];
      this.$apiInstance
        .getSupplierInternalParts(this.$route.params.supplierUUID)
        .then((data) => {
          this.internalParts = data;
        });
    },
    getSupplierFeatures() {
      this.supplierPartQuota = null;
      this.$apiInstance
        .getSupplierFeatures(this.$route.params.supplierUUID)
        .then((data) => {
          this.supplierPartQuota = data.internalPartQuota;
        });
    },
    addPartsFiles(files) {
      this.$refs.uploadOrgPartsRef.partsFilesSelectedChanged(files);
    }
  },
};
</script>
