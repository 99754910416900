var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-internalWarehouseParts"
  }, [_c('v-row', {
    staticClass: "justify-space-between"
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t("InternalWarehouse")))]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('UploadOrgParts', {
    ref: "uploadOrgPartsRef",
    attrs: {
      "warehouse-parts": _vm.internalParts
    },
    on: {
      "PartsUploaded": _vm.getSupplierInternalParts
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-card', [_c('v-card-text', [_c('DropZoneContainer', {
    attrs: {
      "color": "".concat(_vm.$vuetify.theme.defaults.light.success, "DA"),
      "bordered": true,
      "text": _vm.$t('AllowedFormatsList'),
      "text-class": 'text-h6',
      "width": "100%",
      "height": "100%",
      "icon": "fas fa-check",
      "rounded": ""
    },
    on: {
      "drop": _vm.addPartsFiles
    }
  }, [_vm.supplierPartQuota ? _c('PartsTable', {
    attrs: {
      "parts": _vm.internalParts,
      "quota": _vm.supplierPartQuota
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }