<template lang="html" src="./partsTable.template.vue"></template>

<style lang="scss" src="./partsTable.scss"></style>

<script>
import { CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';
const i18nData = require('./partsTable.i18n');

export default {
  name: 'PartsTable',
  props: {
    parts: {
      type: Array,
      required: true,
    },
    quota: {
      type: Number,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headersInternalParts: [],
      formSearch: null,
    };
  },
  created() {
    this.headersInternalParts = [
      { text: this.$t('Name'), value: 'name' },
      {
        text: this.$t('Description'),
        value: 'description',
        sortable: false,
      },
      {
        text: this.$t('InternalReference'),
        value: 'internalReference',
        sortable: true,
      },
      { text: this.$t('Created'),
        value: 'created',
        align: 'center',
        sortable: true },
      {
        text: this.$t('Actions'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  },
  methods: {
    customFilter(items, search, filter) {
      return CustomFilterForSearchDatatable.customFilter(items, search, this);
    },
    goToPageItem(item) {
      this.$router.push({name: 'InternalWarehousePartOverview', params: { supplierUUID: `${this.$route.params.supplierUUID}`, supplierPartUUID: `${item._id}`}});
    },
  },
};
</script>
