var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-partsTable"
  }, [_c('a', {
    ref: "linkPart"
  }), _c('v-row', {
    staticClass: "mx-0 px-5",
    attrs: {
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Quota :")]), _c('v-progress-linear', {
    staticClass: "quota-bar mr-2",
    attrs: {
      "buffer-value": _vm.quota,
      "value": _vm.parts.length,
      "height": "30px",
      "color": "info"
    }
  }), _c('span', [_vm._v("(" + _vm._s(_vm.parts.length) + " / " + _vm._s(_vm.quota) + ")")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "text-field-search",
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search')
    },
    model: {
      value: _vm.formSearch,
      callback: function callback($$v) {
        _vm.formSearch = $$v;
      },
      expression: "formSearch"
    }
  })], 1), _c('v-data-table', {
    staticClass: "cursorTable",
    attrs: {
      "headers": _vm.headersInternalParts,
      "items": _vm.parts,
      "sort-by": "created",
      "sort-desc": "",
      "search": _vm.formSearch,
      "custom-filter": _vm.customFilter,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "click:row": function clickRow($event) {
        return _vm.goToPageItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none d-block text-truncate",
          attrs: {
            "to": {
              name: 'InternalWarehousePartOverview',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                supplierPartUUID: "".concat(item._id)
              }
            },
            "title": item.name
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.description",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none d-block text-truncate",
          attrs: {
            "to": {
              name: 'InternalWarehousePartOverview',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                supplierPartUUID: "".concat(item._id)
              }
            },
            "title": item.description
          }
        }, [_vm._v(" " + _vm._s(item.description) + " ")])];
      }
    }, {
      key: "item.internalReference",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none d-block text-truncate",
          attrs: {
            "to": {
              name: 'InternalWarehousePartOverview',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                supplierPartUUID: "".concat(item._id)
              }
            },
            "title": item.internalReference
          }
        }, [_vm._v(" " + _vm._s(item.internalReference) + " ")])];
      }
    }, {
      key: "item.created",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none d-block text-truncate",
          attrs: {
            "to": {
              name: 'InternalWarehousePartOverview',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                supplierPartUUID: "".concat(item._id)
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long", _vm.$userLocale)) + " ")])];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          attrs: {
            "color": "info",
            "to": {
              name: 'InternalWarehousePartOverview',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                supplierPartUUID: "".concat(item._id)
              }
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-eye ")])], 1)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }